// @flow

import type { Node } from 'react'
import React from 'react'
import SeoPageTemplate from '../scenes/seo-pages'

function SeoFloridaVacationRentals(): Node {
  return <SeoPageTemplate title="Florida Vacation Rentals" />
}

export default SeoFloridaVacationRentals
